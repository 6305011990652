import { css } from "styled-components"

export default css`
  /* Write your custom global CSS here */
  body {
    font-family: sans-serif;
  }
  button {
    cursor: pointer;
  }
`
